import React from "react"
import "antd/lib/modal/style/index.css"
import withLocation from "../../components/withLocation"

class CustomCheckout extends React.Component {
  componentDidMount() {
    this.stripe = window.Stripe(`${process.env.STRIPE_PUBLIC_KEY}`)
    this.redirectToCheckout()
  }

  redirectToCheckout = async () => {
    const { plan, sku, quantity = 1, workbook } = this.props.search
    let cart
    if (plan) {
      cart = { plan, quantity }
    } else if (sku) {
      cart = { sku, quantity }
    } else {
      alert(
        "Please provide a plan or sku (e.g. plan_KEizQVRM5wvh or sku_KEizQVRM5wvh) in the query string"
      )
      return
    }

    if (!workbook) {
      alert("Please provide a workbook name in the query string")
    }

    const { error } = await this.stripe.redirectToCheckout({
      items: [cart],
      successUrl:
        window.location.origin +
        `/individuals/custom-successful-purchase?workbook=${workbook}`,
      cancelUrl: window.location.origin + `/individuals/custom-cancel`,
    })
    if (error) {
      console.warn("Error:", error)
    }
  }

  render() {
    return null
  }
}

export default withLocation(CustomCheckout)
